/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Close, Download, RemoveRedEyeSharp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Papa from 'papaparse';
import { DataGrid } from '@mui/x-data-grid';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';

function CSVFileViewer(props) {
  const { title, path, headerRow = true, color = 'primary' } = props;

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS[0]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [signedUrl, setSignedUrl] = useState('');

  const onClose = () => setShow(false);

  const permissions = useSelector((state) => state.permissions.permissions);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const loadFileFromPath = async () => {
    setIsLoading(true);

    const body = { path };
    const data = await reportApi.getS3AssetSignedUrl(dispatch, body);
    if (data && data.signedUrl) {
      setSignedUrl(data.signedUrl);
      Papa.parse(data.signedUrl, {
        download: true,
        header: headerRow,
        delimitersToGuess: ['|', ','],
        complete(results) {
          if (results.data.length > 0) {
            const columns = [];
            const keys = Object.keys(results.data[0]);
            console.log(keys);
            for (let i = 0; i < keys.length; i++) {
              columns.push({
                field: keys[i],
                headerName: keys[i],
                minWidth: 150,
                disableColumnMenu: true,
              });
            }
            setTableColumns(columns);
            console.log(columns);
            console.log(results.data);
            updateDataSource(results.data);
            setRowCount(results.data.length);
          }
        },
      });
    }
    setIsLoading(false);
    setShow(true);
    console.log(dataSource);
    console.log(rowCount);
  };

  const downloadFile = () => {
    window.open(signedUrl, '_blank');
  };

  return (
    <>
      <Spinner
        color="dark"
        style={{
          display: isLoading ? '' : 'none',
          height: '25px',
          width: '25px',
        }}
      />
      <IconButton
        style={{
          display:
            permissions.SQL_EDITOR.READ === true && !isLoading && path
              ? ''
              : 'none',
        }}
        onClick={() => loadFileFromPath()}
      >
        <RemoveRedEyeSharp color={color} />
      </IconButton>
      <Dialog open={show} onClose={onClose} maxWidth="false">
        <DialogTitle className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            {title}
            <IconButton onClick={() => downloadFile()}>
              <Download color="secondary" />
            </IconButton>
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <DataGrid
              density="compact"
              paginationModel={{ page, pageSize }}
              pagination
              pageSizeOptions={PAGINATION_OPTIONS}
              rowCount={rowCount}
              getRowId={(row) => row._id}
              rows={dataSource}
              columns={tableColumns}
              onPaginationModelChange={handlePageChange}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CSVFileViewer;
